export default {
  head() {
    const head = { meta: [], link: [] }

    const descriptionHidName = {
      hid: 'description',
      name: 'description'
    }
    const ogDescription = {
      hid: 'og:description',
      property: 'og:description'
    }
    const ogTitle = {
      hid: 'og:title',
      property: 'og:title'
    }
    const ogType = {
      hid: 'og:type',
      property: 'og:type'
    }

    // Vook schoolサイト名
    head.meta.push(
      {
        hid: 'og:site_name',
        property: 'og:site_name',
        content: 'Vook school'
      },
      {
        hid: 'apple-mobile-web-app-title',
        property: 'apple-mobile-web-app-title',
        content: 'Vook school'
      }
    )

    // タイトル
    if (this.meta.title) {
      const title = `${this.meta.title}`
      head.title = title
      head.titleTemplate = '%s | Vook school (ヴックスクール)'
      head.meta.push({ ...ogTitle, content: title })
    } else {
      head.title = '動画編集・映像制作の学校 | Vook school (ヴックスクール)'
      head.titleTemplate = ''
      head.meta.push({
        ...ogTitle,
        content: 'Vook school (ヴックスクール)'
      })
    }

    // ディスクリプション
    if (this.meta.description) {
      // 画像URLが先頭と途中で途切れた場合とマークダウンの記述を削除
      this.meta.description = this.meta.description
        .replace(/!\[(.+?\)|.+)|\**\*|>\s|#*#|~~*~|\d{1,}\.|\s\/n\/\d{1,}/g, '')
        .trim()
      head.meta.push({
        ...descriptionHidName,
        content: this.meta.description
      })
      head.meta.push({
        ...ogDescription,
        content: this.meta.description
      })
    } else {
      const description =
        '【最短90日から・メンターと学ぶ映像制作】国内最大級の映像制作メディア「Vook」がプロデュースした映像のプロを目指すスクール。'
      head.meta.push({
        ...descriptionHidName,
        content: description
      })
      head.meta.push({
        ...ogDescription,
        content: description
      })
    }

    // ページタイプ
    if (this.meta.type) {
      head.meta.push({
        ...ogType,
        content: this.meta.type
      })
    } else if (this.$route.path === '/') {
      head.meta.push({
        ...ogType,
        content: 'website'
      })
    }

    // ページURL
    const url = `https://school.vook.vc${this.$route.path}`
    head.meta.push({ hid: 'og:url', property: 'og:url', content: url })

    // OGP画像
    if (this.meta.image) {
      const imageUrl = this.meta.image.startsWith('http')
        ? this.meta.image
        : `https://school.vook.vc${this.meta.image}`
      head.meta.push({
        hid: 'og:image',
        property: 'og:image',
        content: imageUrl
      })
      head.meta.push({
        hid: 'twitter:image',
        name: 'twitter:image',
        content: imageUrl
      })
    }

    // canonical
    // https://www.notion.so/vookinc/URL-f76082856cae41718d0253502f3c5883
    const canonicalUrl = new URL(process.env.BASE_URL)
    canonicalUrl.pathname = this.$route.path
    head.link.push({
      hid: 'canonical',
      rel: 'canonical',
      href: canonicalUrl.href
    })

    // body class
    if (this.bodyAttrs) {
      head.bodyAttrs = { class: this.bodyAttrs.class }
    }

    // 外部 js
    if (this.script) {
      head.script = this.script
    }

    // 外部 css
    if (this.link) {
      head.link = head.link.concat(this.link)
    }

    return head
  }
}
