<template lang="pug">
.accordion(:class="{ 'is-open': expand }")
  .accordion-ttl(@click="toggle()")
    .l-container.is-lg
      .content
        slot(name="title")
  .accordion-body
    .l-container.is-lg
      slot(name="body")
</template>

<script>
export default {
  data() {
    return {
      expand: false
    }
  },
  methods: {
    toggle() {
      this.expand = !this.expand
      this.$emit('faq-open')
    }
  }
}
</script>
